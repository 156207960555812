// src/Form.tsx
import React, { useRef, useState } from "react";
import { Flex, VStack, Button } from "@chakra-ui/react";
import QuestionComponent from "./QuestionComponent";
import { fileLines } from "./fileContent";
import type { Result } from './types';
import { useNavigate } from "react-router-dom";
type Props = {
  setResults: (results: Result[]) => void;
}
const Form = ({setResults}: Props) => {
  const navigate = useNavigate()
  const refs = useRef<any>([]);
  const [allAnswered, setAllAnswered] = useState(false);

  const checkAllAnswered = () => {
    const results = refs.current.map((ref: any) => ref?.getValue());
    setAllAnswered(!results.includes(""));
    // return true
  };

  const handleSubmit = () => {
    const results = refs.current.map((ref: any) =>
      parseInt(ref?.getValue() || 0)
    );
    console.log(results);
    // merge results with fileLines
    const answeredQuestions = fileLines.map((line, index) => {
      return { ...line, answer: results[index] };
    });
    // group by category
    const grouped = answeredQuestions.reduce((acc, curr) => {
      if (!acc[curr.category]) {
        acc[curr.category] = [];
      }
      acc[curr.category].push(curr);
      return acc;
    }, {} as any);
    // sum up totals for each category. if negative is true, multiply by -1
    const totals = Object.keys(grouped).map((key) => {
      const total = grouped[key].reduce((acc: number, curr: any) => {
        return acc + (curr.negative ? curr.answer * -1 : curr.answer);
      }, 0);
      return { category: key, total };
    });
    console.log(totals);
    // now calculate a percentage and give the player one of 16 results
    // to get the percentage, divide the total by 30. if the result is negative,
    // then it leans towards the negative result. if it's positive, it leans towards
    // the positive result
    const categories = {
      A: { negative: "Toxic", positive: "Patient" },
      M: { negative: "Casual", positive: "Competitive" },
      P: { negative: "Mechanical", positive: "Strategic" },
      L: { negative: "Alpha", positive: "Beta" },
    };
      // @ts-ignore
    const percentages: Result[] = totals.map((total) => {
      const percentage = total.total / 30;
      // @ts-ignore
      const category = categories[total.category];
      const result = percentage < 0 ? category.negative : category.positive;
      return { category: total.category, result, percentage };
    });
    console.log(percentages);
    setResults(percentages);
    navigate('/results')
  };
  console.log(allAnswered);
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="100vh"
      padding={4}
    >
      <VStack spacing={8}>
        {fileLines.map((questionObj, index) => (
          <QuestionComponent
            key={index}
            question={questionObj}
            index={index}
            ref={(el) => {
              refs.current[index] = el;
              // @ts-ignore
              el && el.getValue && el.getValue() && checkAllAnswered();
            }}
          />
        ))}
      </VStack>
      <Button
        mt={4}
        colorScheme="blue"
        size="lg"
        onClick={handleSubmit}
        isDisabled={!allAnswered}
      >
        Submit
      </Button >
    </Flex>
  );
};

export default Form;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";
import { RadioOption } from "./types";

// 1. Create a component that consumes the `useRadio` hook
const RadioCard = (props: any) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        width={props.size}
        height={props.size}
        cursor="pointer"
        borderWidth="3px"
        borderRadius="full"
        boxShadow="md"
        transition="background-color 0.2s ease-in-out"
        borderColor={props.color}
        _checked={{
          color: "white",
          bg: props.color,
        }}
        _hover={{
          bg: props.color,
        }}
        mx={3}
      ></Box>
    </Box>
  );
};

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
type Props = {
  radioOptions: RadioOption[];
};
const CustomRadioComponent = forwardRef(({ radioOptions }: Props, ref) => {
  const [value, setValue] = useState("");

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "",
    onChange: setValue,
  });

  useImperativeHandle(ref, () => ({
    getValue: () => value,
  }));

  const group = getRootProps();

  return (
    <HStack {...group}>
      {radioOptions.map((radioOption) => {
        const radio = getRadioProps({ value: radioOption.value });
        return (
          <RadioCard
            key={radioOption.value}
            {...radio}
            color={radioOption.color}
            size={radioOption.size}
          >
            {radioOption.label}
          </RadioCard>
        );
      })}
    </HStack>
  );
});

export default CustomRadioComponent;

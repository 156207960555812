// src/App.tsx
import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import BriarZiggs from './BriarZiggs';
import Results from './Results';
import { Result } from './types';
const App = () => {
  const [results, setResults] = useState<Result[]>([]);
  return ( 
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BriarZiggs setResults={setResults}/>} />
        <Route path="/about" element={<h1>About</h1>} />
        <Route path="/results" element={<Results results={results} />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
const personalities = {
    TCMA: {
      name: "The Enforcer",
      description: "As 'The Enforcer', you dominate the battlefield with your aggressive and assertive playstyle. You combine mechanical prowess with a relaxed attitude, making you a commanding presence in every match. Your confidence and willingness to take charge inspire your team, but your intensity can sometimes lead to conflicts. Despite this, your ability to lead and make impactful plays often turns the tide in your team's favor.",
      champions: ["Darius"],
    },
    TCMB: {
      name: "The Diplomat",
      description: "As 'The Diplomat', you navigate the game with a unique blend of mechanical skill and strategic insight. Your toxic tendencies are balanced by your ability to adapt and understand the game's nuances. You often find yourself in pivotal roles, where your decisions can make or break the game. While your passion for winning can sometimes create tension, your overall impact is undeniable, making you a key player in any team.",
      champions: ["Fizz"],
    },
    TCSA: {
      name: "The Maverick",
      description: "As 'The Maverick', you thrive on unpredictability and innovation. Your casual approach allows you to enjoy the game while constantly experimenting with new strategies and tactics. You are not afraid to take risks and often surprise your opponents with unconventional plays. Your ability to think outside the box and your fearless attitude make you a dynamic and exciting player to watch.",
      champions: ["Bel'Veth", "Garen"],
    },
    TCSB: {
      name: "The Strategist",
      description: "As 'The Strategist', you excel in devising complex game plans and outmaneuvering your opponents. Your toxic side emerges from your intense drive to win, which sometimes leads to friction with teammates. However, your deep understanding of the game's strategic elements often compensates for this, as you guide your team to victory with calculated and intelligent decisions.",
      champions: [],
    },
    TOMA: {
      name: "The Champion",
      description: "As 'The Champion', you are driven by a fierce competitive spirit and a mastery of game mechanics. Your aggressive style and leadership qualities enable you to carry your team to success. You constantly strive for improvement, analyzing your gameplay and adapting to new challenges. Your determination and skill make you a formidable opponent and a respected leader on the battlefield.",
      champions: ["Akali", "Camille", "Draven", "Fiora", "Gragas", "Graves", "Irelia"],
    },
    TOMB: {
      name: "The Executioner",
      description: "",
      champions: ["Brand", "Gangplank", "Gwen"],
    },
    TOSA: {
      name: "The Commander",
      description: "As 'The Commander', you are a natural leader with a sharp strategic mind and a competitive edge. Your aggressive playstyle and decisive actions inspire your team and often lead to game-changing moments. You excel in high-pressure situations, where your ability to coordinate and direct your team proves invaluable. Your leadership and strategic vision make you a cornerstone of any successful team.",
      champions: ["Briar", "Hecarim", "Illaoi", "Jarvan IV", "Jax"],
    },
    TOSB: {
      name: "The Visionary",
      description: "As 'The Visionary', you combine a competitive spirit with exceptional strategic insight. Your ability to see the big picture and anticipate opponents' moves allows you to guide your team to victory. Your leadership is characterized by foresight and adaptability, making you a reliable and inspiring presence. You thrive in challenging environments, where your strategic brilliance and calm demeanor lead your team to success.",
      champions: ["Heimerdinger"],
    },
    PCMA: {
      name: "The Guardian",
      description: "As 'The Guardian', you are a patient and mechanically skilled player who prioritizes the well-being of your team. Your relaxed approach and leadership qualities create a supportive environment, allowing your teammates to thrive. You excel in protecting and assisting your team, often stepping up in critical moments to ensure their success. Your calm and steady presence makes you a reliable and valued teammate.",
      champions: ["Aurora", "Blitzcrank", "Dr. Mundo"],
    },
    PCMB: {
      name: "The Mentor",
      description: "As 'The Mentor', you combine patience with mechanical expertise to guide and nurture your team. Your supportive nature and willingness to share your knowledge make you a key figure in your team's development. You excel at analyzing gameplay and providing constructive feedback, helping your teammates improve and succeed. Your calm and encouraging demeanor fosters a positive and growth-oriented environment.",
      champions: ["Bard", "Caitlyn", "Evelynn"],
    },
    PCSA: {
      name: "The Scholar",
      description: "As 'The Scholar', you approach the game with a patient and analytical mindset. Your casual playstyle allows you to enjoy the game while focusing on strategic depth and long-term goals. You excel in understanding the game's intricacies and outsmarting opponents with well-thought-out plans. Your calm and methodical approach ensures that your team remains focused and on track, even in the most challenging situations.",
      champions: ["Alistar", "Fiddlesticks", "Galio"],
    },
    PCSB: {
      name: "The Sage",
      description: "As 'The Sage', you combine patience with strategic insight and a supportive nature. Your ability to see the bigger picture and guide your team through complex situations makes you a valuable asset. You excel in analyzing the game and providing clear, actionable advice, helping your team make informed decisions. Your calm and wise demeanor fosters a collaborative and effective team dynamic.",
      champions: ["Ahri", "Amumu", "Annie", "Aurelion Sol", "Braum", "Cho'Gath"],
    },
    POMA: {
      name: "The Contender",
      description: "As 'The Contender', you are a patient and highly competitive player who excels in mechanical mastery. Your drive to win and your calm approach under pressure make you a formidable opponent. You constantly strive to improve, analyzing your gameplay and adapting to new challenges. Your determination and skill inspire your teammates and elevate your team's performance.",
      champions: ["Aatrox", "Akshan", "Azir", "Cassiopeia", "Diana", "Elise"],
    },
    POMB: {
      name: "The Advisor",
      description: "As 'The Advisor', you combine patience, competitive spirit, and mechanical expertise to guide your team to victory. Your supportive nature and strategic insight enable you to make impactful decisions and provide valuable advice. You excel in high-stakes situations, where your calm and composed demeanor helps your team navigate challenges and seize opportunities.",
      champions: ["Aphelios", "Corki", "Ekko", "Ezreal", "Gnar", "Hwei"],
    },
    POSA: {
      name: "The Leader",
      description: "As 'The Leader', you are a patient and strategic player with a competitive edge. Your ability to see the big picture and lead your team through challenging situations makes you an invaluable asset. You excel in coordinating efforts and making decisive calls, ensuring that your team stays focused and achieves their goals. Your leadership and strategic vision drive your team's success.",
      champions: [],
    },
    POSB: {
      name: "The Guide",
      description: "As 'The Guide', you are patient, competitive, and strategically minded. Your supportive and insightful approach helps you lead your team to success, making you a trusted leader and advisor. You excel in understanding the game's complexities and providing clear, actionable guidance. Your calm and steady presence fosters a cohesive and effective team dynamic, driving your team towards victory.",
      champions: ["Ashe", "Ivern", "Janna"],
    },
  };
  
  export default personalities;
  
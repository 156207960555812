import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
type Props = {
  handleStart: () => void;
};

const Landing = ({ handleStart }: Props) => {
  return (
    <Container maxW="container.xl" p={4}>
      <Center h="100vh">
        <VStack spacing={8}>
          <Heading as="h1" size="2xl" textAlign="center">
            The Briar Ziggs Playstyle Assessment
          </Heading>
          <Text fontSize="xl" textAlign="center">
            Find your next main here.
          </Text>
          <Stack direction={['column', 'row']} spacing={4}>
            <Button colorScheme="teal" size="lg" onClick={handleStart}>
              Get Started
            </Button>
            <Button colorScheme="gray" size="lg" variant="outline">
              Learn More
            </Button>
          </Stack>
        </VStack>
      </Center>
    </Container>
  );
};

export default Landing;

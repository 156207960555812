const descriptions = {
    Mechanical:
      "You thrive on mastering the intricate mechanics of champions, often focusing on perfecting combos and executing complex plays. Your gameplay is characterized by precise timing, quick reflexes, and a deep understanding of champion abilities. You enjoy the challenge of mechanically intensive champions and constantly seek to refine your skills, taking pride in flashy plays and high-risk, high-reward maneuvers.",
    Strategic:
      "You excel in the strategic aspects of the game, prioritizing game knowledge, map awareness, and macro play. Your focus is on objective control, vision management, and coordinated team efforts. You prefer champions with straightforward mechanics, allowing you to concentrate on broader game plans and outsmarting opponents through superior strategy and tactical execution.",
    Toxic:
      "Your gameplay can be marked by a tendency to engage in negative behavior, such as blaming teammates, trash-talking opponents, or showing poor sportsmanship when things don't go your way. This trait can create a hostile environment and negatively impact team morale. While you may be passionate about the game, your attitude can often overshadow your skill and strategic contributions.",
    Patient:
      "You approach the game with a calm and measured demeanor, valuing consistency and steady progress over immediate results. You are willing to wait for the right moment to strike, focusing on long-term goals and methodical gameplay. Your patience allows you to stay composed under pressure, making thoughtful decisions that benefit your team in the long run.",
    Casual:
      "You play League of Legends primarily for fun and relaxation, without stressing too much about wins or losses. You enjoy experimenting with off-meta builds, playing various champions, and participating in casual game modes like ARAM. Your focus is on having a good time with friends and enjoying the game experience rather than climbing the competitive ladder.",
    Competitive:
      "You have a strong drive to climb the ranked ladder and improve your skills, taking each match seriously and aiming for victory. You frequently analyze your games, study patch notes, and adapt to meta changes to stay ahead. Your competitive nature pushes you to participate in tournaments, track your stats, and set specific goals for improvement, thriving in challenging environments.",
    Alpha:
      "You take on a leadership role in your games, often serving as the shot-caller and guiding your team through crucial moments. Your confidence and decisiveness inspire your teammates, and you are not afraid to make bold moves or take charge in high-pressure situations. You value coordination and teamwork, driving your team towards victory with a commanding presence.",
    Beta: "You prefer to take a supportive role in your games, focusing on assisting your teammates and enabling their success. You are adaptable and willing to fill any necessary role, often prioritizing the needs of the team over your own. Your cooperative nature and willingness to follow strategic calls make you a reliable and valuable team player.",
  };

  export default descriptions;
import React, { useRef } from 'react'
import { Container } from '@chakra-ui/react';
import Landing from './landing';
import Form from './Form';
import type { Result } from './types';
type Props = {
  setResults: (results: Result[]) => void;
}
  
const BriarZiggs = ({setResults}: Props) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToSection = () => {
    if (scrollRef.current) {
      //@ts-ignore
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container maxW="container.md" p={4}>
      <Landing handleStart={scrollToSection}/>
      {/* @ts-ignore */}
      <div ref={scrollRef}>
      <Form setResults={setResults}/>
      </div>
    </Container>
  )
}

export default BriarZiggs
import React from 'react';
import { Flex, Text } from "@chakra-ui/react";
import CustomRadioComponent from "./CustomRadioComponent";
import { Question, RadioOption } from "./types";

type Props = {
  question: Question;
  index: number;
};

const radioButtonOptions: RadioOption[] = [
  {
    value: "-3",
    label: "Strongly Disagree",
    color: "red.500",
    size: "70px",
  },
  {
    value: "-2",
    label: "Disagree",
    color: "red.500",
    size: "55px",
  },
  {
    value: "-1",
    label: "Slightly Disagree",
    color: "red.500",
    size: "45px",
  },
  {
    value: "0",
    label: "Neutral",
    color: "blue.500",
    size: "35px",
  },
  {
    value: "1",
    label: "Slightly Agree",
    color: "green.500",
    size: "45px",
  },
  {
    value: "2",
    label: "Agree",
    color: "green.500",
    size: "55px",
  },
  {
    value: "3",
    label: "Strongly Agree",
    color: "green.500",
    size: "70px",
  },
];

const QuestionComponent = React.forwardRef(({ question }: Props, ref) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="30vh"
      padding={4}
    >
      <Text fontSize="xl" textAlign="center" mb={4}>
        {question.question}
      </Text>
      <Flex direction="row" align="center" justify="space-around">
        <CustomRadioComponent ref={ref} radioOptions={radioButtonOptions} />
      </Flex>
    </Flex>
  );
});

export default QuestionComponent;

export const fileLines = [
  {
    category: "P",
    negative: true,
    question:
      "You prioritize learning your champion's combos to get the upperhand on your opponents.",
  },
  {
    category: "L",
    negative: false,
    question:
      "You would rather be the support than the main damage dealer for your team.",
  },
  {
    category: "M",
    negative: true,
    question: "You prefer casual game modes like ARAM or Arena.",
  },
  {
    category: "A",
    negative: true,
    question:
      "If someone on your team pisses you off, you would intentionally feed to make their day worse.",
  },
  {
    category: "L",
    negative: true,
    question: "You feel comfortable taking kills from your teammates.",
  },
  { category: "A", negative: false, question: "You have a high honor score" },
  {
    category: "P",
    negative: false,
    question:
      "You value vision control and frequently ward key areas of the map.",
  },
  {
    category: "A",
    negative: false,
    question:
      'You gently try to raise team morale with the occasional "gj" or "nj" ',
  },
  {
    category: "M",
    negative: false,
    question: "You study patch notes and adapt quickly to meta changes.",
  },
  {
    category: "M",
    negative: false,
    question:
      "You aim to climb the ranked ladder and improve your skills consistently.",
  },
  {
    category: "P",
    negative: false,
    question:
      "You gravitate towards simple champions that make it easier to focus on the game.",
  },
  {
    category: "P",
    negative: false,
    question: "You focus on improving your overall game strategy.",
  },
  {
    category: "M",
    negative: true,
    question:
      "You play for fun and don't mind losing as long as the game is enjoyable.",
  },
  {
    category: "A",
    negative: true,
    question:
      "When things go wrong, you usually blame someone else, like the jungler.",
  },
  {
    category: "L",
    negative: false,
    question: "You prefer to follow someone else’s lead in-game.",
  },
  {
    category: "L",
    negative: true,
    question: "You handle situations where your team is split on what to do.",
  },
  {
    category: "A",
    negative: true,
    question:
      "When you lose a match, you often think it's due to your team's mistakes.",
  },
  {
    category: "M",
    negative: false,
    question: "You focus on winning and take each match seriously.",
  },
  {
    category: "M",
    negative: true,
    question:
      "You prioritize having a good time with friends over solo climbing.",
  },
  {
    category: "L",
    negative: true,
    question:
      "Leading your team to victory is more satisfying than supporting from the backline.",
  },
  {
    category: "P",
    negative: false,
    question: "You frequently prioritize objectives over kills.",
  },
  {
    category: "M",
    negative: false,
    question: "You analyze your games to find areas for improvement.",
  },
  { category: "A", negative: true, question: "You're casually racist" },
  {
    category: "L",
    negative: false,
    question: "Assists are as important to you as kills.",
  },
  {
    category: "M",
    negative: false,
    question:
      "You keep track of your win rates and stats to monitor your progress.",
  },
  {
    category: "A",
    negative: false,
    question: "You will play losing games out for the chance of victory.",
  },
  {
    category: "P",
    negative: true,
    question:
      "You enjoy mastering intricate mechanics and executing complex plays.",
  },
  {
    category: "L",
    negative: true,
    question: "You are comfortable being the one to initiate team fights.",
  },
  {
    category: "A",
    negative: true,
    question:
      "You often surrender games before 20 minutes if you're not having fun.",
  },
  {
    category: "L",
    negative: false,
    question: "You often play champions that support your team.",
  },
  { category: "A", negative: false, question: "You frequently mute all chat." },
  {
    category: "P",
    negative: true,
    question: "You like one-tricking champions with a high skill ceiling.",
  },
  {
    category: "M",
    negative: true,
    question:
      "You don't stress over performance and just want to relax while playing.",
  },
  {
    category: "M",
    negative: true,
    question: "You enjoy experimenting with off-meta builds and champions.",
  },
  {
    category: "L",
    negative: false,
    question:
      "You don't care if someone else gets the spotlight as long as you win.",
  },
  { category: "P", negative: true, question: "You enjoy hookers." },
  {
    category: "P",
    negative: true,
    question:
      "You are drawn to flashy plays and high-risk, high-reward champions.",
  },
  {
    category: "A",
    negative: true,
    question: "You have received in-game punishments or restrictions.",
  },
  {
    category: "P",
    negative: false,
    question:
      "Doing a lot of damage to towers (and not champions) sounds like a lot of fun.",
  },
  {
    category: "L",
    negative: true,
    question: "You often call for your team to group up.",
  },
];

import {
  Box,
  Heading,
  Text,
  Flex,
  Card,
  CardHeader,
  CardBody,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  CardFooter,
} from "@chakra-ui/react";
import { Result } from "./types";
import personalities from "./personalities";
import descriptions from "./descriptions";

type Props = {
  results: Result[];
};

const categories = {
  A: { negative: "Toxic", positive: "Patient" },
  M: { negative: "Casual", positive: "Competitive" },
  P: { negative: "Mechanical", positive: "Strategic" },
  L: { negative: "Alpha", positive: "Beta" },
};

const sets = ["A", "M", "P", "L"];

const Results = ({ results }: Props) => {
  // Sort results based on the order of sets array
  const sortedResults = results.sort(
    (a, b) => sets.indexOf(a.category) - sets.indexOf(b.category)
  );

  // Get the first letter of each result.result
  const personalityString = sortedResults
    .map((result) => result.result === "Competitive" ? "O" : result.result[0])
    .join("");
  
  
  return (
    <Flex
      direction="row"
      justifyContent="center"
      alignContent="center"
      gap="1vw"
      marginTop="12%"
    >
      <Card width="20vw" variant="outline">
        <CardHeader>
          <Text>Your Personality</Text>
          {/* @ts-ignore */}
          <Heading size="md">{personalities[personalityString].name} ({personalityString})
          </Heading>
        </CardHeader>
        <CardBody>
          {/* @ts-ignore */}
          <Text>{personalities[personalityString].description}</Text>
        </CardBody>
        <CardFooter>
          {/* @ts-ignore */}
          {personalities[personalityString].champions.map((champ) => {return (champ)}
          )}
        </CardFooter>
      </Card>

      <Card width="40vw" variant="outline">
        <CardHeader>
          <Heading size="md">Results</Heading>
        </CardHeader>
        <CardBody>
          <Accordion allowToggle>
            {sortedResults.map((result) => {
              const category = categories[result.category];
              const percentage = Math.floor((result.percentage / 2.0) * 100);
              const min = percentage > 0 ? 0 : 50 + percentage;
              const max = percentage > 0 ? 50 + percentage : 100;

              return (
                <AccordionItem width="100%">
                  <AccordionButton>
                    <Flex direction="column" width="100%">
                      <Text>
                        {Math.abs(percentage) + 50}% {result.result}
                      </Text>
                      <Flex
                        direction="row"
                        justifyContent="space-around"
                        width="100%"
                      >
                        <Flex width="20%" justifyContent="right">
                          <Text paddingRight="20%">{category.negative}</Text>
                        </Flex>
                        <RangeSlider
                          aria-label={["min", "max"]}
                          defaultValue={[min, max]}
                          isReadOnly={true}
                          maxWidth="60%"
                        >
                          <RangeSliderTrack bg="gray.300">
                            <RangeSliderFilledTrack bg="green.300" />
                          </RangeSliderTrack>
                          <RangeSliderThumb boxSize={6} index={0}>
                            <Box color="red" />
                          </RangeSliderThumb>
                          <RangeSliderThumb boxSize={6} index={1}>
                            <Box color="red" />
                          </RangeSliderThumb>
                        </RangeSlider>
                        <Flex width="20%" justifyContent="left">
                          <Text paddingLeft="20%">{category.positive}</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    {/* @ts-ignore */}
                    <Text>{descriptions[result.result]}</Text>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </CardBody>
        <CardFooter>Test Text</CardFooter>
      </Card>
    </Flex>
  );
};

export default Results;
